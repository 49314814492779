<template>
  <div class="News">

    <div class="chooseUni pd40">
      <div class="container">
        <div class="headTit">
          <h3>
            University Search
          </h3>
        </div>
        <div class="searchModel">
          <div class="mods"
               :class="searchModNum == 0 ? 'on':''"
               @click="searchModNum = 0">
            Search by University
          </div>
          <div class="mods"
               :class="searchModNum == 1 ? 'on':''"
               @click="searchModNum = 1">
            Search by specialty
          </div>
        </div>
        <div class="searchBar">
          <div class="item">
            <el-input v-if="searchModNum == 0"
                      placeholder="Enter the university keyword"
                      v-model="inputVal"
                      @keyup.enter="onSearch"
                      clearable></el-input>
            <el-autocomplete v-if="searchModNum == 1"
                             v-model="inputVal"
                             :fetch-suggestions="querySearch"
                             :trigger-on-focus="false"
                             class="inline-input"
                             placeholder="Please enter the full name of your major"
                      @keyup.enter="onSearch"
                             @select="handleSelect" />
          </div>
          <div class="item">
            <el-button type="danger"
                       @click="onSearch"> <i class="el-icon-search"></i> Search</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <el-row :gutter="24">
          <el-col :span="6">
            <h3 class="seaBox">
              <i class="el-icon-search"></i> University Search
            </h3>
            <div>
              <el-form ref="form"
                       :model="form"
                       label-width="0">
                <el-form-item label="">
                  <el-cascader :props="props"
                               v-model="form.city"
                               placeholder="Select City"></el-cascader>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="form.xw"
                             @change="onXw"
                             placeholder="Select Qualifications">
                    <el-option v-for="item in xw"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-select v-model="form.major"
                             placeholder="Select Major"
                             @change="onMajor"
                             filterable>
                    <el-option v-for="item in major"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button class="_globalBtn"
                             @click="onSubmit">Submit</el-button>

                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="18">
            <div style="position: relative;">
              <div class="loadingCont"
                   v-if="showLoading">
                <Loading class="lii"></Loading>
              </div>
              <transition name="el-fade-in-linear">
                <div v-if="!showLoading">
                  <div class="errorCont"
                       v-if="showResult">
                    I'm sorry, I didn't search for the relevant content!
                  </div>
                  <div v-else>
                    <div class="lis"
                         v-for="(item,index) in list"
                         :key="index">
                      <div class="container">
                        <el-row :gutter="24">
                          <el-col :span="6">
                            <div class="imgbox">
                              <img :src="item.thumb"
                                   alt="">
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="text">
                              <h3> {{item.en_name}} </h3>
                              <p><span>City: {{item.province_name}} {{item.city_name}} </span> </p>
                              <p><span>Dominant Major: </span> <b v-for="(item2,index) in item.discipline"
                                   :class="item2 == inputVal ?  'red':''"
                                   :key="index">{{item2}}</b></p>
                            </div>
                          </el-col>
                          <el-col :span="6">
                            <div class="last">
                              <div class="paiming">
                                QS Ranking <span>{{item.rank == 9999 ? '-' : item.rank}}</span>
                              </div>
                              <router-link :to="'/school-detail/'+item.id"
                                           class="_globalBtn">View More</router-link>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                    <el-pagination v-if="showpg"
                                   background
                                   layout="prev, pager, next"
                                   @next-click="onNextPage"
                                   @prev-click="onPrevPage"
                                   @current-change="onCurrentChange"
                                   :page-size="pageSize"
                                   :total="total">
                    </el-pagination>
                  </div>
                </div>
              </transition>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { reactive, toRefs, markRaw, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import Loading from '@/components/LoadingBox'

export default {
  components: {
    Loading
  },
  data () {
    return {
      form: {
        city: '',
        marjor: '',
        xw: '',
        page: 1,
        curPage: 0,
      },
      zhongzhuan: [],
      linshiMajor: [],
      major: [],
      xw: [
        {
          value: 'undergraduate',
          label: 'Undergraduate'
        },
        {
          value: 'masters_degree',
          label: 'Masters Degree'
        },
        {
          value: 'doctoral',
          label: 'Doctoral'
        },
      ],
      cgXw: '',
      cgMajor: '',
      inputVal: '',
      searchModNum: 0,
    }
  },
  setup () {
    const state = reactive({
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level, value } = node
          let arr = []
          if (level > 0) {
            axiosApi(
              'university/province/' + value, {
            }, 'get'
            ).then((res) => {
              res.data.forEach((item) => {
                arr = [...arr, {
                  value: item.code,
                  label: item.en_name,
                  leaf: level >= 1
                }]
              })
              resolve(arr)
            })
          } else {
            axiosApi(
              'university/province', {
            }, 'get'
            ).then((res) => {
              res.data.forEach((item) => {
                arr = [...arr, {
                  value: item.id,
                  label: item.en_name,
                }]
              })
              resolve(arr)
            })
          }
        },
      },
    })
    const list = ref('')
    const total = ref('2')
    const pageSize = ref('12')
    const showpg = ref(false)
    const showResult = ref(false)
    const showLoading = ref(showLoading)
    const onNextPage = (val) => {
      axiosApi(
        'university/searchvocational?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onPrevPage = (val) => {
      axiosApi(
        'university/searchvocational?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onCurrentChange = (val) => {
      axiosApi(
        'university/searchvocational?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const opErrorMsg = (val) => {
      ElMessage.error(val)
    }



    const restaurants = ref([])
    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter(createFilter(queryString))
        : restaurants.value
      // call callback function to return suggestions
      cb(results)
    }
    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    }

    const handleSelect = (item) => {
      console.log(item)
    }
    onMounted(() => {
      axiosApi(
        'university/vocational', {
      }, 'get'
      ).then((res) => {
        let arr = []
        // this.zhongzhuan = markRaw(res.data)
        res.data.forEach((item) => {
          let doctoral = item.doctoral ? item.doctoral : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'doctoral'
            })
          }
          let undergraduate = item.undergraduate ? item.undergraduate : ''
          if (undergraduate.length > 0) {
            undergraduate.forEach((item) => {
              item.father = 'undergraduate'
            })
          }
          let masters_degree = item.masters_degree ? item.masters_degree : ''
          if (masters_degree.length > 0) {
            masters_degree.forEach((item) => {
              item.father = 'masters_degree'
            })
          }
          arr = [...arr, ...doctoral, ...undergraduate, ...masters_degree]
        })
        let deWeightThree = () => {
          let map = new Map();
          for (let item of arr) {
            if (!map.has(item.program)) {
              map.set(item.program, item);
            }
          }
          return [...map.values()];
        }
        let nArr = deWeightThree()
        let nMajor = []
        nArr.forEach((item) => {
          nMajor = [...nMajor, {
            value: item.program,
            label: item.program,
            link: item.program,
            fa: item.father
          }]
        })
        restaurants.value = nMajor
      })
    })

    return {
      ...toRefs(state),
      onNextPage,
      onPrevPage,
      onCurrentChange,
      opErrorMsg,
      list,
      total,
      pageSize,
      showpg,
      showResult,
      showLoading,
      restaurants,
      state1: ref(''),
      state2: ref(''),
      querySearch,
      createFilter,
      handleSelect,
    }
  },
  beforeMount () {
    this.showLoading = true
    const urlSearch = this.$route.query.search
    const searchMod = this.$route.query.searchModNum
    const _searchVal = this.$route.query.searchVal
    const _city = this.$route.query.city
    const _xw = this.$route.query.xw
    const _major = this.$route.query.major

    axiosApi(
      'university/vocational', {
    }, 'get'
    ).then((res) => {
      let arr = []
      this.zhongzhuan = markRaw(res.data)
      res.data.forEach((item) => {
        let doctoral = item.doctoral ? item.doctoral : ''
        if (doctoral.length > 0) {
          doctoral.forEach((item) => {
            item.father = 'doctoral'
          })
        }
        let undergraduate = item.undergraduate ? item.undergraduate : ''
        if (undergraduate.length > 0) {
          undergraduate.forEach((item) => {
            item.father = 'undergraduate'
          })
        }
        let masters_degree = item.masters_degree ? item.masters_degree : ''
        if (masters_degree.length > 0) {
          masters_degree.forEach((item) => {
            item.father = 'masters_degree'
          })
        }
        arr = [...arr, ...doctoral, ...undergraduate, ...masters_degree]
      })
      let deWeightThree = () => {
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.program)) {
            map.set(item.program, item);
          }
        }
        return [...map.values()];
      }
      let nArr = deWeightThree()
      let nMajor = []
      nArr.forEach((item) => {
        nMajor = [...nMajor, {
          value: item.program,
          label: item.program,
          fa: item.father
        }]
      })
      this.linshiMajor = markRaw(nMajor)
      this.major = nMajor
    })
    if (searchMod == 0) {
      axiosApi(
        'university/searchvocational?name=' + urlSearch + '&status=1', {}, 'get'
      ).then(res => {
        this.showLoading = false
        if (res.data.length == 0) {
          this.showResult = true
          console.log(this.showResult)
        }
        else {
          this.showResult = false
          this.showpg = false
          this.list = res.data
        }
      })
    } else if (searchMod == 1) {
      axiosApi(
        'university/searchvocational?master=' + urlSearch + '&status=1', {}, 'get'
      ).then(res => {
        this.showLoading = false
        if (res.data.length == 0) {
          this.showResult = true
          console.log(this.showResult)
        }
        else {
          this.showResult = false
          this.showpg = false
          this.list = res.data
        }
      })
    } else if (this.$route.query.flag == 1) {
      axiosApi(
        'university/searchvocational?name=' + _searchVal + '&city_name=' + _city.slice(0, 4) + '&vocational=' + _xw + '&master=' + _major + '&status=1', {}, 'get'
      ).then(res => {
        this.showLoading = false
        if (res.data.length == 0) {
          this.showResult = true
          console.log(this.showResult)
        }
        else {
          this.showResult = false
          this.showpg = false
          this.list = res.data
        }
      })
    }
    else {
      axiosApi(
        'university/searchvocational', {}, 'get'
      ).then(res => {
        this.showLoading = false
        this.list = res.data.data
        this.showpg = true
        this.list = res.data.data
        this.total = res.data.total
        this.pageSize = res.data.per_page
      })
    }

  },
  methods: {
    onXw (val) {
      this.cgXw = val
    },
    onMajor (val) {
      this.cgMajor = val
    },
    onSubmit () {
      this.showLoading = true
      let city = this.form.city[1] ? this.form.city[1] : ''
      axiosApi(
        'university/searchvocational?city_name=' + city.slice(0, 4) + '&vocational=' + this.form.xw + '&master=' + this.form.major + '&status=1', {}, 'get'
      ).then(res => {
        this.showLoading = false
        if (res.data.length == 0) {
          this.showResult = true
        }
        else {
          this.showResult = false
          this.showpg = false
          this.list = res.data
        }

      })
    },
    onSearch () {
      if (this.inputVal == '') {
        this.opErrorMsg('The content cannot be empty !!!')
      } else {
        this.showLoading = true
        if (this.searchModNum) {
          console.log(this.inputVal)
          axiosApi(
            'university/searchvocational?master=' + this.inputVal + '&status=1', {}, 'get'
          ).then(res => {
            this.showLoading = false
            if (res.data.length == 0) {
              this.showResult = true
              console.log(this.showResult)
            }
            else {
              this.showResult = false
              this.showpg = false
              const arr = res.data
              let nArr = []
              arr.forEach((val, index) => {
                if (val.discipline[0].replace(/(^\s*)|(\s*$)/g, "") == this.inputVal) {
                  nArr = [...nArr, val]
                  arr.splice(index, 1)
                }
              })
              nArr = [...nArr, ...arr]
              console.log(nArr)
              this.list = nArr
            }
          })
        }
        else {
          axiosApi(
            'university/searchvocational?name=' + this.inputVal + '&status=1', {}, 'get'
          ).then(res => {
            this.showLoading = false
            if (res.data.length == 0) {
              this.showResult = true
              console.log(this.showResult)
            }
            else {
              this.showResult = false
              this.showpg = false
              this.list = res.data
            }
          })
        }

      }
    }
  },
  watch: {
    cgXw (val) {
      let oldVal = this.zhongzhuan
      let arr = []
      oldVal.forEach((item) => {
        let doctoral = null
        if (val == 'doctoral') {
          doctoral = item.doctoral ? item.doctoral : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'doctoral'
            })
          }
        }
        if (val == 'undergraduate') {
          doctoral = item.undergraduate ? item.undergraduate : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'undergraduate'
            })
          }
        }
        if (val == 'masters_degree') {
          doctoral = item.masters_degree ? item.masters_degree : ''
          if (doctoral.length > 0) {
            doctoral.forEach((item) => {
              item.father = 'masters_degree'
            })
          }
        }
        arr = [...arr, ...doctoral]
      })
      let deWeightThree = () => {
        let map = new Map();
        for (let item of arr) {
          if (!map.has(item.program)) {
            map.set(item.program, item);
          }
        }
        return [...map.values()];
      }
      let nArr = deWeightThree()
      let nMajor = []
      nArr.forEach((item) => {
        nMajor = [...nMajor, {
          value: item.program,
          label: item.program,
          fa: item.father
        }]
      })
      let nval = this.form
      nval.major = nMajor[0].value
      this.form = nval
      this.major = nMajor
    },
    cgMajor (val) {
      console.log(val)
      let oldArr = this.linshiMajor
      let arr = []
      oldArr.forEach(item => {
        if (item.value == val) {
          arr = [...arr, item.fa]
        }
      })
      let nval = this.form
      nval.xw = arr[0]
      this.form = nval
    },
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner5.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}

.chooseUni {
  padding: 60px;
  background: url(~@/assets/cho.png) center center no-repeat;
  .headTit {
    color: #fff;
    p {
      color: #fff;
    }
  }
  .searchBar {
    display: flex;
    border-radius: 8px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.2);
    background-color: rgb(56, 56, 56);
    margin-bottom: 40px;
    max-width: 800px;
    overflow: hidden;
    background-color: #fff;
    a {
      display: block;
      line-height: 40px;
      text-align: center;
      width: 100px;
      color: #0c3879;
      font-weight: bold;
    }
    .el-select ::v-deep {
      .el-input__inner {
        border: none;
        width: 200px;
        padding-left: 36px;
        --el-input-placeholder-color: #999;
        color: #333;
      }
    }
    .el-input ::v-deep {
      .el-input__inner {
        border: none;
        width: 600px;
        padding-left: 36px;
        background: url(~@/assets/ico4.png) 10px center no-repeat;
        background-size: 16px;
        --el-input-placeholder-color: #999;
        color: #333;
      }
    }
    .el-button {
      width: 200px;
    }
  }
}
.cont {
  .lis {
    margin-bottom: 20px;
    .text {
      h3 {
        font-size: 22px;
      }
      p {
        color: #545454;
        font-size: 14px;
        margin-top: 10px;
        span {
          color: #616161;
        }
        b {
          display: inline-block;
          font-weight: normal;
          padding: 0 5px;
          border-right: 1px solid #999;
          line-height: 1;
          &:last-child {
            border: none;
          }
        }
      }
    }
    .paiming {
      text-align: center;
      font-size: 14px;
      span {
        color: red;
        font-size: 24px;
      }
    }
    ._globalBtn {
      margin-top: 20px;
    }
  }
}

.el-form ::v-deep {
  .el-select,
  .el-button,
  .el-cascader {
    width: 100%;
  }
  .el-button {
    background-color: #0aaaf1;
    border: none;
    color: #fff;
  }
}
h3.seaBox {
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #333;
  margin-bottom: 20px;
}
.errorCont {
  padding-top: 150px;
  text-align: center;
  color: #919191;
}
.loadingCont {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 120px;
}

.searchModel {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  .mods {
    padding: 2px 10px;
    color: #fff;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 7px;
    &.on,
    &:hover {
      background-color: #fff;
      color: #004168;
    }
  }
}
</style>


<style lang="scss">
.el-autocomplete {
  .el-input__inner {
    border: none;
    width: 600px;
    padding-left: 36px;
    background: url(~@/assets/ico4.png) 10px center no-repeat;
    background-size: 16px;
    --el-input-placeholder-color: #999;
    color: #333;
  }
}
</style>